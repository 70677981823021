import React, { useEffect, useState } from 'react'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { Grid, Row } from 'atomic'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { graphql } from 'gatsby'
import { PrivacyContainer } from './styled'
import Banner from './banner'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'

export default props => {
  const { metadata } = props.data.cosmicjsLandingPages

  const [language, setLanguage] = useState('br')

  useEffect(() => {
    setLanguage(props.location.search.replace('?language=', ''))
  }, [props])
  return (
    <IndexLayout location={props.location}>
      <SEO
        socialMedia={{
          //   canonicalUrl: window.location.href,
          title: 'Comunicado de Privacidade',
          description:
            language === 'br'
              ? metadata.banner_destaque.descricao
              : metadata.banner_destaque.descricao_ingles
        }}
      />

      <Grid>
        <TitleWithBreadcrumbRow
          addtionalDictionary={{
            '/comunicado-de-privacidade':
              language === 'br' ? 'Comunicado de Privacidade' : 'Important Notice'
          }}
        />
      </Grid>

      <PrivacyContainer>
        <Grid col={6}>
          <Row>
            <Banner {...metadata.banner_destaque} language={language} />
            <div
              className="privacy-content"
              dangerouslySetInnerHTML={{
                __html:
                  language === 'br'
                    ? metadata.banner_destaque.conteudo
                    : metadata.banner_destaque.conteudo_ingles
              }}
            />
          </Row>
        </Grid>
      </PrivacyContainer>
    </IndexLayout>
  )
}

export const query = graphql`
  query Invasion {
    cosmicjsLandingPages(slug: { eq: "comunicado-de-privacidade" }) {
      id
      metadata {
        banner_destaque {
          titulo
          descricao
          conteudo
          titulo_ingles
          descricao_ingles
          conteudo_ingles
        }
      }
    }
  }
`
