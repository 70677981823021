import styled from 'styled-components'

export const BannerContainer = styled.div`
  .img-banner {
    width: 100%;
  }

  .description {
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    .img-banner {
      margin-top: 20px;
    }
  }
`
