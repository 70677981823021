import React from 'react'

import { TitleStyled } from './styled'

import Stroke from './../stroke'

const Title = props => (
  <>
    <TitleStyled className="title-section" {...props}>
      {props.children}
    </TitleStyled>
    {!props.hideLine && <Stroke lineColor={props.lineColor} />}
  </>
)

Title.defaultProps = {
  hideLine: false,
  color: '#77797b',
  fontSize: '32px',
  lineHeight: '32px',
  fontWeight: '600'
}

export default Title
