import styled from 'styled-components'

export const StrokeStyled = styled.div`
  height: 4px;
  width: 64px;
  display: block;
  background: ${props => props.theme.color.primary};
  margin-top: 15px;

  &.title-components-detail-unit {
    margin-bottom: 30px;
  }
`
