import React from 'react'

import { Row, Col } from 'atomic'

import Title from 'site/src/components/landing-design-system/title'

import { BannerContainer } from './styled'

export default props => {
  return (
    <BannerContainer>
      <Row>
        <Col xs={12} lg={12}>
          <Title>{props.language === 'br' ? props.titulo : props.titulo_ingles}</Title>

          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: props.language === 'br' ? props.descricao : props.descricao_ingles
            }}
          />
        </Col>
      </Row>
    </BannerContainer>
  )
}
