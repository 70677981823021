import styled from 'styled-components'

export const PrivacyContainer = styled.div`
  h1,
  h2,
  h3,
  h4.h5,
  p,
  span,
  a {
    color: #77797b;
  }

  width: 100%;
  overflow-x: hidden;

  padding-bottom: 20px;

  .privacy-content {
    margin-top: 20px;
  }

  .no-padding-col {
    padding: 0px;
    padding-top: 5px;
  }

  .no-padding-bottom {
    margin-bottom: 0;
  }
`
